import Hero from "./components/sections/static/Hero";
import Services from "./components/sections/Services";
import About from "./components/sections/About";
import Projects from "./components/sections/Projects";
import Releases from "./components/sections/Releases";
import Associates from "./components/sections/Associates";
import Customers from "./components/sections/Customers";
import Sponsors from "./components/sections/Sponsors";
import Footer from "./components/sections/static/Footer";

const App = () => {
  return (
    <>
      <Hero />
      <Services />
      <About />
      <Projects />
      <Releases />
      <Associates />
      <Customers />
      <Sponsors />
      <Footer />
    </>
  );
}

export default App;
