import React from "react";
import {Card, CardHeader, CardBody, Divider, Image} from "@nextui-org/react";

const Service = ({name, description}) => {
  const nameArray = name.split(" ");
  return (
    <Card className="max-w-[400px]">
      <CardHeader className="flex gap-3">
        <Image
          alt="nextui logo"
          height={40}
          radius="sm"
          src={`https://placehold.co/60x60/000/FFF?font=montserrat&text=${nameArray[0][0]}${nameArray[1][0]}`}
          width={40}
        />
        <div className="flex flex-col">
          <p className="text-md">{name}</p>
        </div>
      </CardHeader>
      <Divider/>
      <CardBody>
        <p className="max-w-[300px]">{description}</p>
      </CardBody>
    </Card>
  );
}

export default Service;