import { Link } from "@nextui-org/react"
import { IoLogoFacebook, IoLogoInstagram, IoLogoTiktok, IoLogoYoutube, IoMailOutline, IoCallOutline   } from "react-icons/io5"

const Footer = () => {
  return (
    <div className="w-full h-[200px] border-t-[1px] border-[#1A1A1A99] overflow-hidden" id="footer">
        <div className="flex flex-col gap-10 w-full h-full items-center justify-center">
              <div className="flex gap-10 w-full items-center justify-center z-10">
                  <Link href="https://www.facebook.com/naithdefacto/" isExternal className="text-white text-2xl">
                    <IoLogoFacebook />
                  </Link>
                  <Link href="https://www.instagram.com/naithdefacto/" isExternal className="text-white text-2xl">
                    <IoLogoInstagram />
                  </Link>
                  <Link href="https://www.youtube.com/@naithdefacto" isExternal className="text-white text-2xl">
                    <IoLogoYoutube />
                  </Link>
                  <Link href="https://www.tiktok.com/@naithdefacto" isExternal className="text-white text-2xl">
                    <IoLogoTiktok />
                  </Link>
              </div>
              <div className="flex gap-2 w-full items-center justify-center z-10">
                <div isExternal className="text-white text-2xl">
                  <IoMailOutline />
                </div> <p className="text-sm text-stone-100 font-light leading-4 tracking-wide text-center">naithdefacto@gmail.com</p>
                <div isExternal className="text-white text-2xl"> 
                  <IoCallOutline /> 
                </div> <p className="text-sm text-stone-100 font-light leading-4 tracking-wide text-center">+381 65 4409870</p>
              </div>
              <p className="text-sm text-stone-400 font-light leading-4 tracking-wide text-center">© 2024 naithdefacto.com ® All rights reserved.</p>
          </div>
          <img src='./images/blob.svg' alt='blob' className='blur-[100px] hue-rotate-[290deg] mt-[-10rem] lg:mt-[-20rem] opacity-50 z-[5]'></img>
      </div>
  )
}

export default Footer