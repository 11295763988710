import { customers } from '../../resources/data'
import Profile from './Profile'
import Section from './Section'

const Customers = () => {
  return (
    <>
        <Section
            name={'Customers'}
            title={'Meet my customers'}
            id='customers'
        >
            <p className='text-lg font-light text-gray-400 pb-5'>
                Our valued customers experience the perfect blend of quality and affordability. We take pride in delivering services that not only meet but exceed expectations. With an unwavering commitment to excellence, our team ensures that each customer receives top-notch quality while maintaining competitive pricing. It's our dedication to providing exceptional value that sets us apart and keeps our customers satisfied with the results they receive. At the intersection of quality and price, we strive to make every customer's experience not just satisfactory but truly exceptional.
            </p>

            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5'>
                {customers.map((item, i) => (
                    <Profile key={i} profile={item} />
                ))}
            </div>
        </Section>
    </>
  )
}

export default Customers