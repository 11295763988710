import { Button, Image, Link } from "@nextui-org/react";

const NavBar = () => {
  return (
    <div className="fixed z-20 bg-[#00000022] backdrop-blur-lg">
        <div className="w-[100vw] flex max-w-[80%] m-auto h-[90px] items-center justify-between text-white">
            <Link className="flex gap-5 items-center" href="#">
                <Image src="./images/badge.png" className="h-[40px]" />
                <p className="font-bold text-inherit text-white">NDF</p>
            </Link>
            <div className="hidden lg:flex gap-10">
                <Link href="#services" className="text-white">Services</Link>
                <Link href="#about" className="text-white">About Me</Link>
                <Link href="#projects" className="text-white">Projects</Link>
                <Link href="#releases" className="text-white">Releases</Link>
                <Link href="#associates" className="text-white hidden 2xl:flex">Associates</Link>
                <Link href="#customers" className="text-white hidden 2xl:flex">Customers</Link>
                <Link href="#sponsors" className="text-white hidden 2xl:flex">Sponsors</Link>
                <Link href="#footer" className="text-white hidden 2xl:flex">Social Media</Link>
            </div>
            <div>
                <Button variant="bordered" radius="full" className='border-[#5932cd] border-[1px] text-white px-10' as={Link} href="#footer">
                Contact Me
                </Button>
            </div>
        </div>
    </div>
  );
}

export default NavBar