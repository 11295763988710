import AutoplayCarousel from './AutoplayCarousel';

const Slider = () => {
  return (
    <div className='h-[200px] sm:mr-[-5rem] md:mt-[-7rem] overflow-hidden'>
        <AutoplayCarousel />
    </div>
  )
}

export default Slider