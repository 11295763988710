import { IoGlobe, IoLogoGithub, IoLogoLinkedin, IoLogoInstagram, IoLogoYoutube } from "react-icons/io5";

export const services = [
    {name: 'Brand management',      description: 'Cultivating and nurturing brand identity.'},
    {name: 'Digital marketing',     description: 'Online advertising and promotion strategies.'},
    {name: 'Web design',            description: 'Crafting user-friendly website interfaces.'},
    {name: 'Web development',       description: 'Building and maintaining websites.'},
    {name: 'Audio production',      description: 'Creating and editing sound content.'},
    {name: 'Graphics design',       description: 'Visual communication and creative solutions.'},
    {name: 'Clothing collection',   description: 'Curating fashion lines and styles.'},
    {name: 'Media production',      description: 'Content creation for various media channels.'},
    {name: 'Hosting services',      description: 'Providing server space for websites.'},
];

export const moreServices = [
    {
        title: 'Digital Marketing',
        description: 'Elevate your online presence with our strategic digital marketing services. From targeted social media campaigns to data-driven SEO strategies, we ensure your brand captures the attention it deserves in the digital landscape. Maximize visibility, engage your audience, and drive meaningful results with Naithdefacto\'s digital marketing expertise.'
    },
    {
        title: 'Brand Management',
        description: 'Trust us to safeguard and enhance your brand\'s reputation. Our brand management services encompass strategic planning, market analysis, and effective communication strategies. Whether you\'re building a brand from scratch or refining an existing one, Naithdefacto is your partner in cultivating a strong and reputable identity.'
    },
    {
        title: 'Design',
        description: 'Immerse your audience in visually stunning experiences with our design services. From captivating graphics to intuitive user interfaces, our design team combines creativity with functionality. Elevate your brand aesthetic and make a lasting impression with Naithdefacto\'s innovative design solutions.'
    },
    {
        title: 'Clothing Collection',
        description: 'Step into the world of fashion with Naithdefacto\'s curated clothing collection services. Whether you\'re launching a new line or looking to revamp your brand\'s apparel, we bring a blend of style and innovation to the table. From concept to creation, trust us to design and produce clothing that reflects your brand\'s identity and resonates with fashion-forward audiences. Naithdefacto - where your clothing collection becomes a statement.'
    },
    {
        title: 'Audio Production',
        description: 'Bring your brand to life through the power of sound. Our audio production services cover everything from bespoke soundtracks to impactful voiceovers. Let Naithdefacto create an auditory experience that resonates with your audience, setting the perfect tone for your brand.'
    },
    {
        title: 'Media Production',
        description: 'Capture attention and tell compelling stories with our media production services. From captivating videos to visually striking content, we specialize in producing multimedia that leaves a lasting impression. Naithdefacto transforms your ideas into engaging content that resonates across various platforms.'
    },
    {
        title: 'Web Development',
        description: 'Empower your business with robust and scalable web development solutions. Our team of developers ensures your website is not just an online presence but a dynamic tool for success. From e-commerce platforms to custom applications, Naithdefacto\'s web development services cater to your unique needs.'
    },
    {
        title: 'Web Design',
        description: 'Craft visually appealing and user-friendly websites with our web design services. We prioritize aesthetics without compromising functionality, creating an online experience that captivates and converts. Trust Naithdefacto to design a website that aligns seamlessly with your brand and engages your audience.'
    },
    {
        title: 'Hosting',
        description: 'Ensure a secure and reliable online presence with our hosting services. Naithdefacto provides scalable hosting solutions tailored to your business requirements. Experience the peace of mind that comes with high-performance hosting, allowing you to focus on what matters most – your business.'
    }
];

export const projects = [
    {job: 'Websites', duty: 'NDF Team', name: 'Rent A Car DNDX', url: 'https://rentacardndx.com/', imageUrl: 'https://imgur.com/zco4dVA.png'},
    {job: 'Websites', duty: 'NDF Team', name: 'Rent A Car DNDX | Old', url: 'https://rentacardndx.com/', imageUrl: 'https://imgur.com/fdbYOp0.png'},
    {job: 'Websites', duty: 'NDF Team', name: 'Var Montaza', url: 'https://www.varmontaza.rs/', imageUrl: 'https://imgur.com/lR754jn.png'},

    {job: 'Graphics design', duty: 'sketch', name: 'Some', url: '#', imageUrl: 'https://nextui.org/images/card-example-2.jpeg'},
    {job: 'Graphics design', duty: 'design', name: 'Designs', url: '#', imageUrl: 'https://nextui.org/images/card-example-2.jpeg'},
    {job: 'Graphics design', duty: 'design', name: 'to display', url: '#', imageUrl: 'https://nextui.org/images/card-example-2.jpeg'},

    {job: 'Digital marketing', duty: 'advertise', name: 'A certain company', url: '#', imageUrl: 'https://nextui.org/images/card-example-2.jpeg'}
];

export const associates = [
    {
        name: 'Ahmed Spahić',
        nickname: 'daddyDOT',
        description: 'Ambitious and friendly individual, interested in programming and graphics design.',
        imageUrl: 'https://avatars.githubusercontent.com/u/65982671',

        socials: [
            {icon: <IoLogoGithub />, url: 'https://github.com/daddyDOT'},
            {icon: <IoLogoLinkedin />, url: 'https://www.linkedin.com/in/ahmed-spahi%C4%87-0b5016260/'},
        ]
    },
    {
        name: 'Robson Dantas',
        nickname: 'TW',
        description: 'Ambitious and friendly individual, interested in programming and graphics design.',
        imageUrl: 'https://imgur.com/1Bou9Wu.jpg',
        
        socials: [
            {icon: <IoLogoGithub />, url: 'https://dribbble.com/rrobsonleoni'},
            {icon: <IoLogoLinkedin />, url: 'https://www.linkedin.com/in/robson-leoni-993082166/'},
            {icon: <IoLogoInstagram />, url: 'https://www.instagram.com/rrobsonleoni/'},
        ]
    }
];

export const customers = [
    {
        name: 'Rent A Car DNDX',
        nickname: 'Business',
        description: 'We are in the business of renting out vehicles.',
        imageUrl: 'https://rentacardndx.com/images/logo.webp',

        socials: [
            {icon: <IoGlobe />, url: 'https://rentacardndx.com/'},
        ]
        
    },

    {
        name: 'Var Montaza',
        nickname: 'Business',
        description: 'We make and install constructions for various purposes .',
        imageUrl: 'https://www.varmontaza.rs/assets/img/logo1.png',

        socials: [
            {icon: <IoGlobe />, url: 'https://www.varmontaza.rs/'},
        ]
        
    },
];

export const cardDetails = [
    {
        imgUrl: './images/1050x600/whrlbe-logo.png',
        siteUrl: '',
        title: 'NDF'
    },
    {
        imgUrl: './images/1050x600/defactodivision-logo.png',
        siteUrl: '',
        title: 'DF'
    },
    {
        imgUrl: './images/1050x600/websolucije-logo.png',
        siteUrl: '',
        title: 'NDF'
    },
    {
        imgUrl: './images/300x200/reebok-logo.png',
        siteUrl: '',
        title: 'DF'
    },
    {
        imgUrl: './images/300x300/hp-logo.png',
        siteUrl: '',
        title: 'NDF'
    },
    {
        imgUrl: './images/300x300/fc-logo.png',
        siteUrl: '',
        title: 'DF'
    },
    {
        imgUrl: './images/300x300/lfs-logo.png',
        siteUrl: '',
        title: 'NDF'
    },
    {
        imgUrl: './images/250x200/incurve-logo.png',
        siteUrl: '',
        title: 'DF'
    },
    {
        imgUrl: './images/250x200/namecheap-logo.png',
        siteUrl: '',
        title: 'DF'
    },
    {
        imgUrl: './images/250x200/affinity-logo.png',
        siteUrl: '',
        title: 'DF'
    },
];