import Section from "./Section"

const About = () => {
  return (
    <Section
        name={'About me'}
        title={'A short introduction of myself'}
        right={<img src="./images/present.png" className="sticky top-[1rem] pl-[4rem] pt-[4rem]" alt="ndf-present"/>}
        id='about'
    >
        <p className='text-lg font-light text-gray-400 pb-5'>
            Meet an entrepreneur and digital creator dedicated to infusing creativity into every aspect of life. With a profound passion for the digital world, this visionary individual aspires to make every endeavor a creative masterpiece, particularly when it comes to helping businesses grow.
            <br /><br />
            As a seasoned entrepreneur, this individual leverages expertise to navigate the ever-evolving digital landscape, recognizing creativity's pivotal role in shaping brand identities and fostering business success.
            <br /><br />
            Beyond the digital realm, a strong appreciation for art drives inspiration, leading to the creation of engaging and meaningful content. This individual is a firm believer in the transformative power of storytelling, visual aesthetics, and innovation.
            <br /><br />
            The passions of this entrepreneur extend beyond the screen to the thrill of the road, including a deep interest in cars, supermoto, drift, and stunts. The precise intersection of skill and adrenaline in these pursuits resonates, turning each adventure into a captivating story.
            <br /><br />
            Fueling both entrepreneurial spirit and creativity, this individual also embraces the joy of travel. Exploring diverse horizons, experiencing various cultures, and capturing the essence of each journey contributes to a continual wellspring of inspiration for their creative endeavors.
            <br /><br />
            In addition to these pursuits, this entrepreneur thrives on challenges, consistently seeking opportunities to do things in the best possible way. Whether it's tackling complex projects or pushing creative boundaries, this individual's dedication to excellence is evident in every endeavor. Challenges are not obstacles; they are stepping stones to achieving the extraordinary.
        </p>
    </Section>
  )
}

export default About