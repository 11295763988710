import { Link, Spacer } from "@nextui-org/react";
import { cardDetails } from "../../../resources/data";
import "./slider.scss";

export default function AutoplayCarousel() {

    const CarouselItem = ({ data }) => (
      <Spacer className="flex justify-center items-center w-full h-full" as={Link} href={data.siteUrl} isExternal>
        <img src={data.imgUrl} alt={data.title}></img>
      </Spacer>
    )

  return (
    <div className="overflow-hidden relative h-full w-[200%] sm:w-[200%] md:w-full">
      <div className="flex absolute left-0 justify-center items-center h-[80px] gap-10 w-[200%] h-full carousel-track">
        {cardDetails.map((detailKey) => {
          return (
            <CarouselItem data={detailKey} />
          );
        })}
        {cardDetails.map((detailKey) => {
          return (
            <CarouselItem data={detailKey} />
          );
        })}
      </div>
    </div>
  );
}