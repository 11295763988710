import React from "react";
import {Card, CardHeader, CardBody, CardFooter, Divider, Link, Image} from "@nextui-org/react";

const Profile = ({profile}) => {
  return (
    <Card className="max-w-[400px]">
      <CardHeader className="flex gap-3">
        <Image
          alt="avatar"
          height={40}
          radius="sm"
          src={profile.imageUrl}
          width={40}
        />
        <div className="flex flex-col">
          <p className="text-md">{profile.name}</p>
          <p className="text-small text-default-500">{profile.nickname}</p>
        </div>
      </CardHeader>
      <Divider/>
      <CardBody>
        <p>{profile.description}</p>
      </CardBody>
      <Divider/>
      <CardFooter className="flex gap-3">
        {profile.socials?.map((item) => (
          <Link
            isExternal
            href={item.url}
          >
            {item.icon}
          </Link>
        ))}
      </CardFooter>
    </Card>
  );
}

export default Profile