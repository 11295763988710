import Section from "./Section"
import Slider from "./Slider/Slider"

const Sponsors = () => {
  return (
    <div className="pb-[5rem] md:pb-[10rem]">
      <Section
          name={'Sponsors'}
          title={'Who is sponsoring us?'}
          id='sponsors'
      >
          <p className='text-lg font-light text-gray-400 pb-5 w-[70%]'>
            Our sponsors play a pivotal role in fueling our creative endeavors. Their support enables us to push boundaries and bring innovative projects to life. We are grateful for the partnerships that help us make a lasting impact and create valuable content for our audience. Together with our sponsors, we embark on a journey of collaboration and shared success, turning visions into reality.
          </p>
      </Section>
      <Slider />
    </div>
  )
}

export default Sponsors