import React, { useEffect, useState } from 'react'
import { IoLogoYoutube } from "react-icons/io5";
import Video from './Video';
import Section from './Section';

const Releases = () => {

    const currentChannelId = 'UCRxrdugS6Fi5XF7HZwjTDEA';
    const baseUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D';
    const [videos, setVideos] = useState([]);

    useEffect(() => {       
        (async () => {
            if (currentChannelId) {
                try {
                    const data = await fetch(`${baseUrl}${currentChannelId}`).then(response => response.json());
                    setVideos(data.items);
                } catch (error) {
                    console.log(error);
                }
            }
        })();
    }, [currentChannelId]);

  return (
    <Section
        name={'Releases | SOON'}
        title={'Some of my latest content'}
        id='releases'
    >
        <IoLogoYoutube className='text-5xl text-red-500 mb-4' />
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5'>
            {videos.slice(0, 4).map((video) => (
                <Video key={video.guid} video={video} />
            ))}
        </div>
    </Section>
  )
}

export default Releases