import { useEffect, useState } from 'react';
import { Button, Link } from '@nextui-org/react';

const selectRandomEntity = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);

    return array[randomIndex];
}

const Hero = () => {

    const initialImages = [
      "./images/1050x600/ndf-logo.png",
      "./images/300x200/irln-logo.png",
      "./images/300x300/hp-logo.png",
      "./images/250x200/incurve-logo.png"
    ];
    const [images, setImages] = useState(initialImages);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
      const resources = [
        ['./images/1050x600/ndf-logo.png',
        './images/1050x600/defactodivision-logo.png',
        './images/1050x600/websolucije-logo.png',
        './images/1050x600/whrlbe-logo.png'],

        ["./images/300x200/irln-logo.png",
        "./images/300x200/l4r-logo.png",
        "./images/300x200/reebok-logo.png"],

        ["./images/300x300/hp-logo.png",
        "./images/300x300/fc-logo.png",
        "./images/300x300/lfs-logo.png"],

        ["./images/250x200/incurve-logo.png",
        "./images/250x200/namecheap-logo.png",
        "./images/250x200/affinity-logo.png"],
    ];
    
    const intervalId = setInterval(() => {
      const randomImages = [];
      setOpacity(0);
      setTimeout(() => {
          randomImages.push(selectRandomEntity(resources[0]));
          randomImages.push(selectRandomEntity(resources[1]));
          randomImages.push(selectRandomEntity(resources[2]));
          randomImages.push(selectRandomEntity(resources[3]));
          setImages(randomImages);
          setOpacity(1);
      }, 900);
    }, 5000);
  
    return () => clearInterval(intervalId);
  }, []);
    
  return (
    <>
      <div className="w-[100vw] h-[100vh] flex items-center justify-between max-w-[80%] m-auto overflow-hidden">
        <div className='w-full lg:w-[60%] text-white flex flex-col gap-10 z-10'>
          <h1 className='text-4xl font-semibold sm:text-7xl lg:w-[90%] md:font-normal'>Welcome to Naithdefacto website</h1>
          <p className='text-lg line-clamp-5 sm:line-clamp-none lg:w-[90%] 2xl:w-[70%] font-light text-gray-400'>Discover a realm of cutting-edge digital solutions designed to elevate both individuals and businesses. Our tailored experiences are crafted to meet unique needs, ushering you into a world where innovation meets practicality. Embrace the future of technology with us, as we guide you through a seamless journey of digital transformation, redefining possibilities along the way.</p>
          
          <div className='flex flex-col gap-3 sm:flex-row sm:gap-0'>
          <Button variant="bordered" radius="full" className='border-[#5932cd] border-[1px] text-white px-10' as={Link} href='#services'>
            Services
          </Button>
          <Button variant="bordered" radius="full" className='border-[0px] text-gray-500 px-10' as={Link} href='#about'>
            Go to my biography
          </Button>
          </div>
        </div>

        <div className='absolute right-1 lg:static w-[40%] 2xl:w-[50%] flex items-center justify-center'>
          <img src='./images/blob.svg' alt='blob' className='blur-[100px] hue-rotate-[290deg] absolute'></img>
          
          <div className='z-[0] opacity-20 lg:opacity-100 lg:z-10 2xl:w-[150%]'>
            <div class="angry-grid">
                <div id="item-0">
                    <img class="easeload" style={{opacity: opacity}} src={images[0]} alt='test'></img></div>
                <div id="item-1">
                    <img class="easeload" style={{opacity: opacity}} src={images[1]} alt='test'></img></div>
                <div id="item-2">
                    <img class="easeload" style={{opacity: opacity}} src={images[2]} alt='test'></img></div>
                <div id="item-3">
                    <img class="easeload" style={{opacity: opacity}} src={images[3]} alt='test'></img></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;