import { Accordion, AccordionItem, Card, CardHeader, Image, Link } from "@nextui-org/react"
import Section from "./Section"
import { projects } from "../../resources/data"

const Projects = () => {

  const projectList = () => {
    const accordions = [
        {area: 'Graphics design'},
        {area: 'Websites'},
        {area: 'Digital marketing'},
    ];
    
    return (
        <Accordion defaultExpandedKeys={["2"]}>
            {accordions.map((acc, i) => (
                <AccordionItem key={i+1} aria-label={acc.area} subtitle="Press to expand" title={acc.area}>
                    <div className="grid grid-cols-2 gap-10">
                        {projects.filter((project) => project.job === acc.area).map((item) => (
                            <Card className="col-span-1 h-[200px] cursor-pointer" as={Link} href={item.url} isExternal>
                                <CardHeader className="absolute z-10 pt-[1rem] pb-[5rem] flex-col !items-start bg-gradient-to-b from-[#000000CF] to-[#00000000]">
                                    <p className="text-tiny text-white/60 font-bold">{item.duty}</p>
                                    <h4 className="text-white font-medium text-large">{item.name}</h4>
                                </CardHeader>
                                <Image
                                    removeWrapper
                                    alt="Card background"
                                    className="z-0 w-full h-full object-cover"
                                    src={item.imageUrl}
                                />
                            </Card>
                        ))}
                    </div>
                </AccordionItem>
            ))}
        </Accordion>
    )
  }

  return (
    <Section
        name={'Projects'}
        title={'Explore some of my projects'}
        right={projectList()}
        id='projects'
    >
        <p className='text-lg font-light text-gray-400 pb-5'>
            Our global projects reflect a commitment to innovation and positive change. From digital marketing endeavors to creative initiatives, our team collaborates on projects that transcend borders, aiming to make a meaningful impact on a global scale. Together, we leverage creativity and expertise to contribute to a better world through our diverse and far-reaching projects.
        </p>
    </Section>
  )
}

export default Projects