import {Accordion, AccordionItem} from "@nextui-org/react";
import Section from "./Section";
import { moreServices, services } from "../../resources/data";
import Service from "../Service";

const Services = () => {

  const servicesList = () => {
    return (
      <div className="flex flex-wrap gap-5 sticky lg:top-[1rem] lg:pt-[7rem]">
      {services.map((item) => (
          <Service
          key={item.name}
          name={item.name}
          description={item.description}
          />
      ))}
      </div>
    )
  };

  return (
    <Section
        name={'Services'}
        title={'What do I offer?'}
        right={servicesList()}
        id='services'
    >
        <p className='text-lg font-light text-gray-400'>
        Are you eager to see your business reach new heights of success? Look no further! With my wealth of experience and a passion for delivering results, I bring a wide range of skills to the table and a firm commitment to helping your business flourish.
        <br /><br />Using a careful and thorough approach, coupled with a deep understanding of market trends, I provide tailored solutions crafted specifically for your business's unique challenges and opportunities. Whether you're a small startup or a well-established company, I'm dedicated to creating strategies that drive your business forward.
        </p>
        <Accordion>
          <AccordionItem className="ml-[-0.5rem]" key="1" aria-label="Learn more" title="Learn more">
            {moreServices.map((item) => (
              <div key={item.title}>
                <h4 className='text-2xl font-Regular text-white pb-3'>{item.title}</h4>
                <p className='text-lg font-light text-gray-400 pb-5'>{item.description}</p>
              </div>
            ))}
          </AccordionItem>
        </Accordion>
    </Section>
  )
}

export default Services