const Section = ({name, title, right, id, children}) => {
  return (
    <div className='flex flex-col lg:flex-row gap-5 lg:gap-[7rem] max-w-[80%] m-auto relative isolate pt-[5rem] md:pb-[10rem]' id={id}>
        <div className={`flex flex-col gap-2 lg:w-[${right ? '50%' : '100%'}]`}>
          <h6 className='text-lg font-bold text-[#5932cd]'>{name}</h6>
          <h2 className='text-5xl font-regular text-white mb-5'>{title}</h2>
          {children}
        </div>
        {right && 
          <div className="lg:w-[50%] lg:sticky lg:right-0 lg:top-[1rem]">
            {right}
          </div>
        }
    </div>
  )
}

export default Section