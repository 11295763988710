import { Card, CardHeader, Image } from '@nextui-org/react'
import React from 'react'

const Video = ({video}) => {
  return (
    <a target="_blank" rel='noreferrer' href={video.link}>
        <Card className="w-[300px] h-[200px]">
            <CardHeader className="absolute bottom-0 z-10 pb-[1rem] pt-[5rem] flex-col !items-start bg-gradient-to-t from-[#000000CF] to-[#00000000]">
                <p className="text-tiny text-white/60 font-bold">{video.title}</p>
            </CardHeader>
            <Image
                removeWrapper
                alt="thumbnail"
                className="z-0 w-full h-full object-cover"
                src={`https://i4.ytimg.com/vi/${video.guid.split(':')[2]}/mqdefault.jpg`}
            />
        </Card>
    </a>
  )
}

export default Video